import axios from "axios";

import {
  getRefreshToken,
  removeTokens,
  setTokens,
} from "store/AccessTokenStore";
import { axiosInstance } from "./BaseService";
import { config } from "config/environment.config";

export const googleLogin = async (code) => axiosInstance.post("/auth/google", { code });

export const login = async (body) => {
  try {
    return await axiosInstance.post("/auth/login", body);
  } catch (error) {}
};

export const refreshTokens = async () => {
  try {
    const response = await axios.post(
      `${config.API_HOST}/auth/refresh`,
      {},
      {
        headers: {
          authorization: `Bearer ${getRefreshToken()}`,
        },
      }
    );
    setTokens(response.data);
  } catch (err) {
    window.location.href = "/login";
    removeTokens();
  }
};

export const getAllTask = (data) => axiosInstance.post("/tasks/filtered", data);

export const getOneTask = async (route) => {
  try {
    return await axiosInstance.get(route);
  } catch (e) {}
};
